import React from 'react';
import {useEffect, useState, useRef} from 'react'

import spotifyUri from 'spotify-uri'

const YOUTUBE_REGEX = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?[\w\?=]*)?/
function StatusStuff({status, hide}) {

  const youtubeLink = status.match(YOUTUBE_REGEX)

  let spotifyURI = null
  const spotifyMatch = status.match(/\S*spotify\S*/)
  if (spotifyMatch && spotifyMatch[0]) {
    try {
      const parsedSpotifyLink = spotifyUri.parse(spotifyMatch[0]);
      spotifyURI = spotifyUri.formatURI(parsedSpotifyLink);
    } catch (e) {
    }
  }
  return <div style={{
    display: hide ? 'none' : 'flex',
    flexDirection: 'column',
  }}>
    {(youtubeLink && youtubeLink[1]) && 
    <iframe
      width="100%"
      height="120"
      src={`https://www.youtube.com/embed/${youtubeLink[1]}`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
        ></iframe>
    }
    {spotifyURI && <iframe
      src={`https://open.spotify.com/embed?uri=${spotifyURI}`}
      width="100%"
      height="140"
      frameBorder="0"
      allowTransparency="true"
      allow="encrypted-media"></iframe>}
  </div>
}
export default StatusStuff
