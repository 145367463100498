import React from 'react';
import {useEffect, useState, useRef} from 'react'

// TODO: thomas webcam screensharing extensie(?)
// TODO: escape to exit fullscreen
// TODO: sliders firefox.
// TODO: mobile view

import './App.css'

import notificationSound from './notification.mp3'

import StatusStuff from './StatusStuff'

const options = {
  serviceUrl: 'wss://meet.jit.si/xmpp-websocket?room=winakelderstuderen',
  hosts: {
    domain: 'meet.jit.si',
    muc: 'conference.meet.jit.si'
  },
};

const confOptions = {
  openBridgeChannel: true
};

const initOptions = {
  disableAudioLevels: true,
  desktopSharingChromeExtId: 'mbocklcggfhnbahlnepmldehdhpjfcjp',
  desktopSharingChromeDisabled: false,
  desktopSharingChromeSources: ['screen', 'window'],
  desktopSharingChromeMinExtVersion: '0.1',
  desktopSharingFirefoxDisabled: true
};
const {
  CONNECTION_ESTABLISHED,
  CONNECTION_FAILED,
  CONNECTION_DISCONNECTED,
} = JitsiMeetJS.events.connection;

const {
  USER_JOINED,
  PHONE_NUMBER_CHANGED,
  USER_LEFT,
  CONFERENCE_JOINED,
  TRACK_ADDED,
  TRACK_REMOVED,
  DISPLAY_NAME_CHANGED,
  SUBJECT_CHANGED,
  TRACK_MUTE_CHANGED,
}= JitsiMeetJS.events.conference

const VIDEO_CONSTRAINTS = {
  resolution:  480,
  constraints: {
    video: {
      width:  { ideal: 858},
      height: { ideal: 480},
      facingMode: "user"
    }
  }
}

const {DEVICE_LIST_CHANGED} = JitsiMeetJS.events.mediaDevices;

/* global JitsiMeetJS */

JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

const useForceUpdate = () => useState()[1];


function Track({track, muted, onMuteChanged = () => {}, volume, flipped}) {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const {
      TRACK_AUDIO_LEVEL_CHANGED,
      TRACK_MUTE_CHANGED,
      LOCAL_TRACK_STOPPED,
      TRACK_AUDIO_OUTPUT_CHANGED,
    } = JitsiMeetJS.events.track;


    track.addEventListener(TRACK_AUDIO_LEVEL_CHANGED, audioLevel => console.log(`Audio Level local: ${audioLevel}`));
    track.addEventListener(TRACK_MUTE_CHANGED, () => onMuteChanged(track.isMuted()));
    track.addEventListener(LOCAL_TRACK_STOPPED, () => console.log('local track stoped'));
    track.addEventListener(TRACK_AUDIO_OUTPUT_CHANGED, deviceId => console.log(`track audio output device was changed to ${deviceId}`));


    const current = playback.current
    return () => {
      track.detach(current)
    }
  }, [])

  const playback = useRef()

  useEffect(() => {
    track.attach(playback.current);
    playback.current.muted = !!muted
  }, [playback])

  // this doesnt work with props for some reason (?)
  useEffect(() => {
    playback.current.muted = !!muted
  }, [muted])

  useEffect(() => {
    if (volume === undefined)
      return
    playback.current.volume = volume
  }, [volume])


  const isVideo = track.getType() === 'video';
  if (isVideo)
    return <video autoPlay={1} ref={playback} style={{
      transform: flipped ? 'rotateY(180deg)' : ''
    }}/>
  else
    return <audio autoPlay={1} ref={playback}/>
}

const longIdToShortId = longId => longId.split('/')[1]

function getPeopleFromRoom(room) {
  if (room == null)
    return []
  const myId = room.myroomjid;
  const people = [
    ...Object.entries(room.lastPresences).filter(p => p[0] != myId),
    [myId, room.presMap.nodes] // me
  ].map(([id, props]) => {
    const name = props.find(prop => prop.tagName == 'nick');
    if (!name) {
      return
    }
    try {
      return {
        isMe: id == myId,
        id: longIdToShortId(id),
        props: JSON.parse(name.value)
      }
    } catch (e) {
      return null
    }
  }).filter(Boolean)

  return people
}

let updateInfo = null



function PersonMe({tracks={}, person, inactive, setLocalTracks, conference}) {
  const {video, audio} = tracks;

  const [videoMuted, setVideoMuted] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);

  const toggleMuteVideo = () => {
    if (!video)
      return
    if (video.isMuted()){
      video.unmute();
      setVideoMuted(false);
    } else {
      video.mute();
      setVideoMuted(true);
    }
  }

  const toggleMuteAudio = () => {
    if (!audio)
      return
    if (audio.isMuted()){
      audio.unmute();
      setAudioMuted(false);
    } else {
      audio.mute();
      setAudioMuted(true);
    }
  }

  useEffect(() => {
    if(!video)
      return 
    setVideoMuted(video.isMuted())
  }, [video])

  useEffect(() => {
    if(!audio)
      return 
    setAudioMuted(audio.isMuted())
  }, [audio])

  const disableVideo = async () => {
    const {video} = tracks || {};
    if (!video)
      return
    await video.dispose();
    // await video.conference.removeTrack(video);
  }

  const toggleScreenSharing = async () => {
    const { LOCAL_TRACK_STOPPED } = JitsiMeetJS.events.track;

    console.log('Removing all video tracks');
    const trackType = (!video || video.getUsageLabel() === 'camera') ? 'desktop' : 'video'

    let newTracks;
    try {
      newTracks = await JitsiMeetJS.createLocalTracks({
        devices: [trackType],
        ... (trackType == 'desktop' ? {} : VIDEO_CONSTRAINTS)
      })
    } catch (e) {
      console.info('Canceled ... ');
      return
    }

    await disableVideo()

    // newTracks.forEach(t => t.addEventListener(LOCAL_TRACK_STOPPED, disableVideo))
    console.log('adding them to the conference:', newTracks);
    const [newVideo] = newTracks
    conference.addTrack(newVideo)
    setLocalTracks(t => ({...t, video: newVideo}))
  }


  return <div className="person me">
    <div className='person-header'>
      <div className='person-left' title='Update status' onClick={() => {
        !inactive && updateInfo({status: prompt('Update status:', person.props.status) || person.props.status})
      }}>
        <div className='person-name'>{person.props.name}</div>
        <div className='person-status'>{person.props.status}</div>
      </div>

      <div className='person-right'>
        {!inactive && 
        <div onClick={toggleScreenSharing} title="Toggle screensharing">
          <span className="material-icons">
            {(video && video.getUsageLabel() == 'camera') ? 'cast' : 'cast_connected'}
          </span>
        </div>}
        {(!inactive && video) && 
        <div onClick={toggleMuteVideo} title="Toggle webcam">
          <span className="material-icons">
            {videoMuted ? 'visibility_off' : 'visibility'}
          </span>
        </div>}
      {!inactive &&
      <div onClick={toggleMuteAudio} title="Toggle mic">
        <span className="material-icons">
          {audioMuted ? 'mic_off' : 'mic'}
        </span>
      </div>
      }</div>
    </div>
    <StatusStuff status={person.props.status} />
    <div className='video'>
      {(video && !videoMuted) &&
      <Track key={video} track={video} muted={true} flipped={video.getUsageLabel() === 'camera'}/>
      }</div>
      {audio && <Track key={audio} track={audio} muted={true} />}
    </div>
}

function PersonOther({tracks = {}, person, me, inactive}) {
  const {video, audio} = tracks;

  // console.log('OTHER', tracks, person.props.name, {video, audio});

  const [muted, setMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);

  const [volume, _setVolume] = useState(0);
  const setVolume = (volume) => {
    _setVolume(volume);
    const volumes = JSON.parse(localStorage.volumes || '{}');
    volumes[person.props.name] = volume;
    localStorage.volumes = JSON.stringify(volumes)
  }

  useEffect(() => {
    const volumes = JSON.parse(localStorage.volumes || '{}');
    _setVolume(volumes.hasOwnProperty(person.props.name) ? volumes[person.props.name] : 1);
  }, [person.props.name])

  useEffect(() => {
    if(!video)
      return 
    setVideoMuted(video.isMuted())
  }, [video])

  useEffect(() => {
    if(!audio)
      return 
    setMuted(audio.isMuted())
  }, [audio])

  const [isBig, _makeBig] = useState(false)
  const makeBig = fn => {
    _makeBig((oldState) => {
      const newState = fn(oldState);
      if (newState == true) {
        video.conference.selectParticipants([person.id])
      } else {
        video.conference.selectParticipants([])
      }
      return newState
    })
  }

  return <div className={`person ${isBig ? 'big' : ''}`}>
    <div className='person-header'>
      <div className='person-left'>
        <div className='person-name'>{person.props.name}</div>
        <div className='person-status'>{person.props.status}</div>
      </div>
      <div className='person-right'>
        {(audio && !muted) && <input type='range' min={0} max={1} step={0.02} value={volume} onChange={e => setVolume(parseFloat(e.target.value))}/>}
        {(audio && muted) && <span className="material-icons cursor-default" title="User has disabled their microphone">mic_off</span>}
      </div>
    </div>
    <StatusStuff status={person.props.status} hide={isBig} />
    {video && <div className='video' onClick={() => makeBig(b => !b)} style={{
      display: videoMuted ? 'none' : 'inherit'
    }}>
      <Track key={video} track={video} volume={volume}  muted={person.props.room !== me.props.room} onMuteChanged={m => setVideoMuted(m)}/>
  </div>}
    {audio && <Track key={audio} track={audio} volume={volume} muted={person.props.room !== me.props.room} onMuteChanged={m => setMuted(m)}/>}
  </div>
}


function App() {
  const [remoteTracks, setRemoteTracks] = useState({});
  const [localTracks, setLocalTracks] = useState({});
  const [conference, setConference] = useState(null)

  const [people, setPeople] = useState([])

  const [notification, setNotification] = useState()

  useEffect(() => {

    JitsiMeetJS.init(initOptions);

    let connection = new JitsiMeetJS.JitsiConnection(null, null, options);
    let conference = null;


    const onConnectionSuccess = () => {
      console.log('Connection success!');
      conference = connection.initJitsiConference('winakelderstuderen', confOptions);

      setConference(conference)

      conference.on(CONFERENCE_JOINED, ()  => {

        console.log('Conference joined');
        console.log('joined conference, adding localTracks');


        let latestInfo = {}
        updateInfo = (info) => {
          const newInfo = {...latestInfo, ...info}
          conference.setDisplayName(JSON.stringify(newInfo));
          latestInfo = newInfo
          localStorage.info = JSON.stringify(newInfo)
          setPeople(getPeopleFromRoom(conference.room))
        }

        let savedInfo = null
        try {
          savedInfo =JSON.parse(localStorage.info)
        } catch(e) {
          console.error(e);
          updateInfo({
            name: prompt("Naam?"),
            room: null,
            status: 'Just joined!',
            host: window.location.host
          })
        }

        if(savedInfo) {
          updateInfo({
            ...savedInfo,
            room: savedInfo.name.includes('Laptop') ? 'Studeren' : null
            // room: 'TESTING'
          })
        }

        window.conference = conference

        // setTimeout(() => {
          setPeople(getPeopleFromRoom(conference.room))
        // }, 1000)

        const addTracks = tracks => {
          tracks.forEach(track => conference.addTrack(track))
        }

        JitsiMeetJS.createLocalTracks({
          devices: ['video', 'audio'],
          ...VIDEO_CONSTRAINTS
        })
          .then(addTracks)
          .catch(error => {
            console.error('Error creating local video tracks', error)

            JitsiMeetJS.createLocalTracks({
              devices: ['video'],
              ...VIDEO_CONSTRAINTS
            })
              .then(addTracks)
              .catch(error => {
                console.error('Error creating local video tracks', error)
              });

            JitsiMeetJS.createLocalTracks({
              devices: ['audio'],
            }).then(addTracks).catch(error => {
              console.error('Error creating local audio tracks', error)
            })
          });
      });

      conference.on(USER_LEFT, (id) => {

        setPeople(getPeopleFromRoom(conference.room))
        setRemoteTracks(remoteTracks => ({
          ...remoteTracks,
          [id]: {audio: null, video:null}
        }))
      });

      conference.on(TRACK_ADDED, (track) => {
        console.log('ADDED TRACK', track);
        if (track.isLocal()) {
          setLocalTracks(tracks => ({...tracks, [track.getType()]:track}))
        } else {
          const participant = track.getParticipantId();
          setRemoteTracks(remoteTracks => ({
            ...remoteTracks,
            [participant] : {...(remoteTracks[participant] || {}), [track.getType()]:track}
          }))
        }
      });

      conference.on(TRACK_REMOVED, track => {
        console.log('TRACK REMOVED!');
        if (track.isLocal()) {
          setLocalTracks(tracks => ({...tracks, [track.getType()]:null}))
        } else {
          const participant = track.getParticipantId();
          setRemoteTracks(remoteTracks => ({
            ...remoteTracks,
            [participant]: { ...(remoteTracks[participant] || {}), [track.getType()]:null}
          }))
        }
      });


      [
        'CONFERENCE_JOINED',
        'CONFERENCE_FAILED',
        'CONFERENCE_JOINED',
        'CONFERENCE_FAILED',
        'CONFERENCE_JOINED',
        'CONFERENCE_LEFT',
        'AUTH_STATUS_CHANGED',
        'PARTCIPANT_FEATURES_CHANGED',
        'USER_JOINED',
        'USER_LEFT',
        'USER_STATUS_CHANGED',
        'USER_ROLE_CHANGED',
        'TRACK_ADDED',
        'TRACK_REMOVED',
        'TRACK_AUDIO_LEVEL_CHANGED',
        'TRACK_MUTE_CHANGED',
        'SUBJECT_CHANGED',
        'LAST_N_ENDPOINTS_CHANGED',
        'P2P_STATUS',
        'PARTICIPANT_CONN_STATUS_CHANGED',
        'DOMINANT_SPEAKER_CHANGED',
        'CONFERENCE_CREATED_TIMESTAMP',
        'CONNECTION_INTERRUPTED',
        'CONNECTION_RESTORED',
        'DISPLAY_NAME_CHANGED',
        'BOT_TYPE_CHANGED',
        // 'ENDPOINT_MESSAGE_RECEIVED',
        'LOCK_STATE_CHANGED',
        'PARTICIPANT_PROPERTY_CHANGED',
        'KICKED',
        'PARTICIPANT_KICKED',
        'SUSPEND_DETECTED',
        'START_MUTED_POLICY_CHANGED',
        'STARTED_MUTED',
        'DATA_CHANNEL_OPENED',
      ].map(name => {
        console.assert(JitsiMeetJS.events.conference.hasOwnProperty(name), `${name} not found ...`);
        conference.on(JitsiMeetJS.events.conference[name], (...args) => {
          console.info(name, ...args);
        });
      })

      conference.on(USER_JOINED, id => {
        const newPeople = getPeopleFromRoom(conference.room)
        setPeople(newPeople)

        // console.log(newPeople, id);
        // setNotification(`${newPeople.find(p => p.id == id).props.name} joined!`)
      });
      conference.on(TRACK_MUTE_CHANGED, track =>  {
        // forces update
        setRemoteTracks(remoteTracks => remoteTracks);
      });

      conference.on(SUBJECT_CHANGED, (subject) => {
      })
      conference.on(DISPLAY_NAME_CHANGED, (id, displayName) => {

        setPeople((oldPeople) => {
          const newPeople = getPeopleFromRoom(conference.room)
          console.log(oldPeople, newPeople);

          const oldPerson = oldPeople.find(p => p.id == id);
          const newPerson = newPeople.find(p => p.id == id);

          if (!oldPerson || !newPerson || !oldPerson.props || !newPerson.props)
            return newPeople

          if (newPerson.props.room != oldPerson.props.room) {
            setNotification(`${newPerson.props.name} has changed room to ${newPerson.props.room}`)
          }
          if (newPerson.props.status != oldPerson.props.status) {
            setNotification(`${newPerson.props.name} has changed their status to "${newPerson.props.status}"`)
          }
          console.table([oldPerson, newPerson]);
          return newPeople
        });

        // const oldPerson = people.find(p => p.id == id);
        // if (!oldPerson)
        //   return
      });
      // conference.on(TRACK_AUDIO_LEVEL_CHANGED, (userID, audioLevel) => console.log(`${userID} - ${audioLevel}`));
      // conference.on(PHONE_NUMBER_CHANGED, () => console.log(`${conference.getPhoneNumber()} - ${conference.getPhonePin()}`));
     
      console.log('Joining conference!');
      conference.join();


    }

    const onConnectionFailed = () => console.error('Connection Failed!')


    const disconnect = () => {
      connection.removeEventListener(CONNECTION_ESTABLISHED, onConnectionSuccess);
      connection.removeEventListener(CONNECTION_FAILED, onConnectionFailed);
      connection.removeEventListener(CONNECTION_DISCONNECTED, disconnect);
    }

    connection.addEventListener(CONNECTION_ESTABLISHED, onConnectionSuccess);
    connection.addEventListener(CONNECTION_FAILED, onConnectionFailed);
    connection.addEventListener(CONNECTION_DISCONNECTED, disconnect);

    const onDeviceListChanged = (devices) => console.info('current devices', devices)
    JitsiMeetJS.mediaDevices.addEventListener(DEVICE_LIST_CHANGED, onDeviceListChanged);

    connection.connect();


    const unload = () => {
      setLocalTracks(localTracks => {
        localTracks.video && localTracks.video.dispose();
        localTracks.audio && localTracks.audio.dispose();
        return {video: null, audio: null}
      })
      conference.leave();
      connection.disconnect();
    }

    window.addEventListener('beforeunload', unload)
    window.addEventListener('unload', unload)
  }, [])

  const [overview, setOverview] = useState(true);

  const me = people.find(p => p.isMe);
  if (!me)
    return <Loading />

  const myRoom = me.props.room


return (
  <div className="app">
    {overview || !myRoom
      ? <Overview
          people={people}
          remoteTracks={remoteTracks}
          localTracks={localTracks}
          setOverview={setOverview}
        />
      : <RoomView
          setLocalTracks={setLocalTracks}
          people={people}
          remoteTracks={remoteTracks}
          localTracks={localTracks}
          setOverview={setOverview}
          conference={conference}
        />}
    <Notification text={notification} />
  </div>
);
}


function Notification({text}) {
  const [texts, setTexts] = useState([])
  const audio = useRef();
  useEffect(() => {
    if (!text)
      return;
    audio.current.currentTime = 0;
    audio.current.volume = 0.3;
    audio.current.play();
    setTexts(ts => [...ts, text])
    setTimeout(() => {
      setTexts(ts => ts.filter(t => t != text))
    }, 10000)
  }, [text])
  return <div className='notifications'>
    {texts.map((t, i) => <div className='notification' key={t}>{t}</div>)}
    <audio ref={audio} src={notificationSound}/>
  </div>
}

// {myRoom && <div className='button-switch-overview' onClick={() => setOverview(o=>!o)}>
//   <span className="material-icons">
//     {overview ? 'view_module' : 'view_column'}
//   </span>
// </div>}

function RoomView({people, localTracks, remoteTracks, setOverview, setLocalTracks, conference}) {
  const me = people.find(p => p.isMe);
  const room = me.props.room;
  const rooms = [... new Set([...people.map(p=> p.props.room)])].filter(Boolean)

  return <div className="tiled">
    <div className='rooms-header' onClick={() => setOverview(true)}>
      {rooms.map(r => {
        const number = people.filter(p => p.props.room == r).length
        return <span key={r} className={`room-name ${r == room ? 'active' : ''}`}>{r} ({number})</span>
      }
      )}
    </div>
    <div className='people'>
      {people.filter(p => p.props.room == room).map(person => {

        if (person.isMe)
          return <PersonMe tracks={localTracks} person={person} key={person.id} setLocalTracks={setLocalTracks} conference={conference}/>
            else
          return <PersonOther tracks={remoteTracks[person.id]} person={person} me={me} key={person.id}/>
      })}
    </div>
  </div>
}

function Overview({people, remoteTracks, localTracks, setOverview}) {
  const rooms = [... new Set([...people.map(p=> p.props.room)])].filter(Boolean)
  const me = people.find(p => p.isMe);

  const putMeInFront = array => [array.find(p => p.isMe), ...array.filter(p => !p.isMe)].filter(Boolean)

  return <div className="overview">
  {rooms.map(room => {
    const number = people.filter(p => p.props.room == room).length;
    return <div className='room' key={room} onClick={(e) => {e.preventDefault(); updateInfo({room: room}); setOverview(false)}}>
    <div className='room-header'>{room} ({number})</div>
      <div className='people'>
        {putMeInFront(people.filter(p => p.props.room == room)).map(person => {

          if (person.isMe)
            return <PersonMe inactive tracks={localTracks} person={person} key={person.id}/>
              else
            return <PersonOther inactive tracks={remoteTracks[person.id]} person={person} me={me} key={person.id}/>
        })}
      </div>
    </div>
  })}
  <div className='room' onClick={() => {
    updateInfo({room: prompt('Name of room?') || 'Random Room'});
    setOverview(false);
  }}>
    <div className='room-header' >New room</div>
  </div>
  </div>
}

function Loading() {
  const [text, setText] = useState('Loading')
  useEffect(() => {
    let timeout = null
    timeout = setTimeout(() => {
      setText('Loading ...')
      timeout = setTimeout(() => {
        setText('Really loading, I swear!')
        timeout = setTimeout(() => {
          setText('Hold tight!')
          timeout = setTimeout(() => {
            setText('Okay, maybe try reloading the page ...')
          }, 3000)
        }, 6000)
      }, 4000)
    }, 4000)
    return () => clearTimeout(timeout)
  }, [])
  return <div className='loading'>{text}</div>
}
export default App;
